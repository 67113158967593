<template>
  <div class="card">
    <h3 class="card-title">Rectificar DDJJ de cargas de familia para la percepción de asignaciones familiares</h3>
    <FormPercepcionCargasFamilia
      v-if="asignacionFamiliar"
      :user="user"
      :asignacion-familiar="asignacionFamiliar"
      :edit-mode="true"
      @submitted="save"
    />
  </div>
</template>
<script>
import CrearPercepcionCargasFamiliaHijos
  from "@/modules/percepcionesAsignacionFamiliar/components/CrearPercepcionCargasFamiliaHijos.vue";
import Modal from "@/components/elements/Modal.vue";
import FormPercepcionCargasFamilia
  from "@/modules/percepcionesAsignacionFamiliar/components/FormPercepcionCargasFamilia.vue";
import {mapState} from "vuex";
import {asignacionesFamiliaresApi} from "@/modules/percepcionesAsignacionFamiliar/services/asignacionesFamiliaresApi";
import {
  convertCargasFamiliaToFormData
} from "@/modules/percepcionesAsignacionFamiliar/services/percepcionCargasFamiliaForm";
import {percepcionCargasFamiliaApi} from "@/modules/percepcionesAsignacionFamiliar/services/percepcionCargasFamiliaApi";
import Swal from "sweetalert2";

export default {
  name: "EditarPercepcionCargasFamilia",
  components: {FormPercepcionCargasFamilia, Modal, CrearPercepcionCargasFamiliaHijos},
  data() {
    return {
      asignacionFamiliar: null
    }
  },
  computed: {
    ...mapState(["user"]),
  },
  mounted() {
    asignacionesFamiliaresApi
      .show(this.$route.params.id)
      .then(asignacionFamiliar => this.asignacionFamiliar = asignacionFamiliar)
  },
  methods: {
    save(percepcionCargasFamilia) {

      const formData = convertCargasFamiliaToFormData(percepcionCargasFamilia)

      percepcionCargasFamiliaApi.put(this.asignacionFamiliar.percepcion_cargas_familia.id, formData).then((response) => {
        if (response.success) {
          Swal.fire("Éxito", "Se guardó correctamente", "success");
          this.$router.push({name: "ListarPercepciones"});
        } else {
          Swal.fire("Error", "No se pudo guardar", "error");
        }
      })
        .catch((error) => {
          Swal.fire(
            "Error",
            `<p>Error de servidor</p><p>${error}</p>`,
            "error"
          );
        });
    }
  }
}
</script>

<style scoped>

</style>
